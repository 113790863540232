import set from "lodash/fp/set";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setElementResponses } from "../redux/sign.slice";

const useSetElementResponse = (index, element) => {
  const dispatch = useDispatch();
  const { doc } = useSelector(state => state.sign);

  const setResponse = (response) => {
    if(element.cloned) setClonesResponse(response);
    else dispatch(setElementResponses({ index, response }))
  }

  const setClonesResponse = (response) => {
    doc.elements.forEach((elm, index) => {
      if(element.uuid === elm.uuid && elm.cloned){
        dispatch(setElementResponses({ index, response }))
      }
    })
  }
 
  return {
    setResponse
  }
};

export default useSetElementResponse;