import {useRef, useEffect, useMemo} from "react"
import { PiTrashLight } from "react-icons/pi";
import { TbLetterA } from "react-icons/tb";
import { TfiClose } from "react-icons/tfi";
import { Rnd } from "react-rnd";
import style from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setActiveIndex, setElements, setMovingElement } from "../../redux/sign.slice";
import { cloneDeep } from "lodash";
import { useState } from "react";
import IconButton from "../Buttons/IconButton";
import { useLocation, useParams } from "react-router-dom";
import ElementCustomize from "../Builder/Customize/ElementCustomize";
import { COLORS } from "../../utils/colors";
import useSetElementResponse from "../../hooks/useSetElementResponse";

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'


export default function ElementsContainer({
  index,
  element,
  children,
  resizable = true,
  lockAspectRatio = true,
  initialResizeHandleClasses = {},

  radioIndex=0,
}) {
  const ref = useRef(null);
  const { recipientId } = useParams();
  const dispatch = useDispatch();
  const [isHovering, setHover] = useState(false);
  const [elementOptionsVisibility, setElementOptionsVisibility] = useState(false);
  const { doc, builderStatus, activeIndex, zoomLevel } = useSelector((state) => state.sign);
  const isActive = activeIndex === index && builderStatus === "edit";
  const { setResponse } = useSetElementResponse(index, element);
  const { pathname } = useLocation();
  const basePath = pathname.replace(`/${doc._id}`, '');

  const handleActive = (status) => {
    if(builderStatus !== "edit") return;
    dispatch(setActiveIndex(status?index:null));
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setElementOptionsVisibility(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const onResizeStop = (e, direction, ref, delta, position) => {
    if(builderStatus !== "edit") return;
    let newElement = cloneDeep(element);
    if(newElement.type==="radio"){
      newElement.position[radioIndex] = {
        x: position.x / zoomLevel,
        y: position.y / zoomLevel,
      };
    }else {
      newElement.position = {
        x: position.x / zoomLevel,
        y: position.y / zoomLevel,
      };
    }
    newElement.style.width = parseFloat(ref.style.width);
    newElement.style.height = parseFloat(ref.style.height);
    dispatch(setElements({ element: newElement, index }));

    setTimeout(() => {
      dispatch(setMovingElement({ prevent: false }));
    }, 300);
  };

  const onDragStop = (_, d) => {
    if(builderStatus !== "edit") return;
    let newElement = cloneDeep(element);
    if(newElement.type==="radio"){
      console.log("QITU APO")
      newElement.position[radioIndex] = { x: d.x / zoomLevel, y: d.y / zoomLevel };
    }else {
      newElement.position = { x: d.x / zoomLevel, y: d.y / zoomLevel };
    }
    dispatch(setElements({ element: newElement, index }));
  };

  const deleteElement = () => {
    if(builderStatus !== "edit") return;
    dispatch(setElements({ element: null, index }));
  };

  const updateVariant = (type = "small") => {
    if(builderStatus !== "edit") return;
    let newElement = cloneDeep(element);
    let variant = newElement.style.variants[type];
    Object.keys(variant).map((type) => {
      newElement.style[type] = variant[type];
    });
    dispatch(setElements({ element: newElement, index }));
  };

  const onResizeStart = () => {
    dispatch(setMovingElement({ prevent: true }));
  }

  const removeElementResponse = () => {
    if(element.recipient !== recipientId) return;
    setResponse(null);
  }

  const hasElementResponse = useMemo(() => {
    if(doc.type === "fill" || element?.responses?.length < 1 || !element?.responses[0]?.value) return false;
    else return true;
  }, [element]);

  const getRecipientIndex = useMemo(() => {
    if(basePath==="/send/bulk") {
      if(!element.recipient) return 0;
      else return 1;
    }
    return doc.recipients.findIndex(recipient => recipient._id === element.recipient)
  }, [element, doc.recipients]);

  const elementColor = () => {
    if(doc.type==="fill") return "transparent";
    else if(builderStatus!=="fill") return "#FFF7C8";
    else if((!hasElementResponse||isHovering) && element.recipient===recipientId) return "#FFF7C8";
  }

  return (
    <Rnd
      size={{
        width: element.style.width * zoomLevel,
        height: element.style.height * zoomLevel,
      }}
      position={{
        x: element.type==="radio"?element.position[radioIndex]?.x * zoomLevel : element.position.x * zoomLevel,
        y: element.type==="radio"?element.position[radioIndex]?.y * zoomLevel : element.position.y * zoomLevel
      }}
      // minWidth={element.style?.min?.width}
      // minHeight={element.style?.min?.height}
      // maxWidth={element.style?.max?.width}
      // maxHeight={element.style?.max?.height}
      onDragStop={onDragStop}
      onResizeStop={onResizeStop}
      className={`
        ${style.container} 
        ${isActive && style.active} 
        ${element.preview?"pointer-events-none":"pointer-events-auto"}
      `}  
      lockAspectRatio={lockAspectRatio}
      resizeHandleClasses={{
        bottomRight: isActive ? style.resizeDot : null,
        top: "pointer-events-none",
        bottom: "pointer-events-none",
        left: "pointer-events-none",
        topRight: "pointer-events-none",
        bottomLeft: "pointer-events-none",
        topLeft: "pointer-events-none",
        right: "pointer-events-none",
        ...initialResizeHandleClasses,
      }}
      onMouseDown={() => handleActive(true)}
      onDoubleClick={() => setElementOptionsVisibility(true)}
      bounds={`.drag-container-${element.page}`}
      enableResizing={builderStatus !== "edit"?false:resizable}
      disableDragging={builderStatus !== "edit"}
      onClick={(e) => e.stopPropagation()}
      onResizeStart={onResizeStart}
      style={{
        background: elementColor()
      }}
      onMouseEnter={() => builderStatus === "fill" && setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        ref={ref}
        id={`id_${element._id}`}
        data-tooltip-id={`tooltip_${element._id}`}
        data-tooltip-content={element?.value?.tooltip}
        style={{ padding: 4*zoomLevel}}
        className={`all_rendered_elements h-full z-[-1] content-container ${element.isRequired && 'pl-3'}`}
      >
        {isActive && (
          doc.type === "fill" ? (
            <div className="absolute top-[-40px] element-actions">
              <div className="inline-flex rounded-md shadow-sm" role="group">
                <button
                  type="button"
                  onClick={() => updateVariant("small")}
                  className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                >
                  <TbLetterA size={13} />
                </button>
                <button
                  type="button"
                  onClick={() => updateVariant("big")}
                  className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                >
                  <TbLetterA size={16} />
                </button>
                <button
                  type="button"
                  onClick={deleteElement}
                  className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                >
                  <PiTrashLight />
                </button>
              </div>
            </div>
          ) : (
            elementOptionsVisibility && <ElementCustomize index={index} element={element} />
          )
        )}
        {(element.isRequired && doc.type !== "fill" && (element.recipient===recipientId||builderStatus!=="fill")) && (
          <span 
            style={{fontSize: 16*zoomLevel}} 
            className="absolute top-[2px] left-[2px] text-red-500 font-bold"
          >*</span>
        )}
        
        {children}

        {builderStatus === "fill" && isHovering && hasElementResponse && doc.type !== "fill" && element.recipient === recipientId && !element.isReadonly && (
          <IconButton onClick={removeElementResponse} classes="absolute right-[5px] top-[50%] translate-y-[-50%] z-50">
            <TfiClose size={12} />
          </IconButton>
        )}

        {doc.type !== "fill" && builderStatus === "edit" && (
          <div className={style.colorTriangle}>
            <div className={style.child} style={{ background: COLORS[getRecipientIndex]}}></div>
          </div>
        )}
        {builderStatus==="edit" && element?.value?.tooltip && (
          <Tooltip 
            id={`tooltip_${element._id}`} 
            style={{ 
              padding: "4px 8px",
              fontSize: 12
            }} 
          />
        )}
      </div>
    </Rnd>
  );
}
