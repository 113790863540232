import React, { useEffect, useMemo, useRef, useState } from "react";
import { SignatureModal } from "../../components";
import { Document, Page, pdfjs } from "react-pdf";
import { ALLELEMENTS, getElement } from "../../utils/elements";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveIndex,
  setElementToAdd,
  setElements,
  setModalSignType,
  setMovingElement,
  setZoomLevel,
} from "../../redux/sign.slice";
import { CiZoomIn, CiZoomOut } from "react-icons/ci";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import useLastSignatureElement from "../../hooks/useLastSigantureElement";
import { omit } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { API_URL } from "../../utils/api";
import { GoStop } from "react-icons/go";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function DocumentBuilder({ 
  classes, 
  headerComponent, 
  signComponent, 
  handleApply,
  isSend=false,
  pagesRef=null
}) {
  
  const dispatch = useDispatch();
  const documentRef = useRef(null);
  const { doc, zoomLevel, builderStatus, elementToAdd, movingElement, modalSignType } =
    useSelector((state) => state.sign);
  const isDocumentSigned = useSelector((state) => state.app.isDocumentSignedState);
  const [totalPages, setTotalPages] = useState({});
  const [navigating, setNavigating] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastInitialElement, lastSignatureElement] = useLastSignatureElement();

  // console.log("---", doc.elements);

  const handleZoomIn = () => {
    dispatch(setZoomLevel(Math.min(1.5, zoomLevel + 0.1)));
  };

  const handleZoomOut = () => {
    dispatch(setZoomLevel(Math.max(0.4, zoomLevel - 0.1)));
  };

  const addElementToPage = (e, index) => {
    if (!elementToAdd) return;
    const rect = e.currentTarget.getBoundingClientRect();

    const newElement = omit(movingElement, ['preview', 'show']);
    
    let position = [];
    if(newElement.type==="radio") {
      position[0] = {
        x: (e.clientX - rect.left) / zoomLevel,
        y: (e.clientY - rect.top) / zoomLevel,
      };
      position[1] = {
        x: (e.clientX - rect.left) / zoomLevel,
        y: ((e.clientY - rect.top) + 35) / zoomLevel,
      };
    }else {
      position = {
        x: (e.clientX - rect.left) / zoomLevel,
        y: (e.clientY - rect.top) / zoomLevel,
      };
    }

    const element = {
      ...ALLELEMENTS[elementToAdd.type],
      ...newElement,
      page: index,
      uuid: uuidv4(),
      position
    };

    console.log(element);

    dispatch(setElements({ element, index: -1 }));
    dispatch(setActiveIndex(doc.elements.length));
    if (movingElement?.removeAfterAdd) {
      dispatch(setMovingElement(null));
      dispatch(setElementToAdd(null));
    }
  };

  const handleMouseLeave = (e) => {
    if (!movingElement?.type) return;
    dispatch(setMovingElement({ show: false }));
  };

  const handleMouseMove = (e) => {
    if (!movingElement?.type) return;
    const elements = document.elementsFromPoint(e.clientX, e.clientY);
    const childElement = elements.find(
      (element) =>
        element.classList.contains("react-draggable") ||
        element.classList.contains("element-actions")
    );
    if (childElement) dispatch(setMovingElement({ show: false }));
    else {
      const rect2 = documentRef.current.getBoundingClientRect();
      const top = documentRef.current.scrollTop + (e.clientY - rect2.top);
      let position = [];
      if(movingElement.type==="radio") {
        position[0] = {
          x: (e.clientX - rect2.left) / zoomLevel,
          y: top / zoomLevel,
        };
        position[1] = {
          x: (e.clientX - rect2.left) / zoomLevel,
          y: (top + 35) / zoomLevel,
        };
      }else {
        position = {
          x: (e.clientX - rect2.left) / zoomLevel,
          y: top / zoomLevel,
        };
      }
      dispatch(
        setMovingElement({
          show: true,
          position
        })
      );
    }
  };

  const prevPage = (currentPage) => {
    if (currentPage > 1) {
      setNavigating(true);
      scrollToPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage) => {
    if (currentPage < pdfsTotalPages) {
      setNavigating(true);
      scrollToPage(currentPage + 1);
    }
  };

  const handleScroll = (e) => {
    if (navigating) return;
    if (movingElement?.type) {
      dispatch(setMovingElement({ show: false }));
    }

    const scrollTop = documentRef.current.scrollTop;
    const pageHeight = documentRef.current.scrollHeight / pdfsTotalPages;
    const newPage = Math.min(
      pdfsTotalPages,
      Math.ceil((scrollTop + 150) / pageHeight)
    );
    setCurrentPage(newPage);
  };

  let timeout = null;
  const scrollToPage = (pageNumber) => {
    const div =
      documentRef?.current?.querySelectorAll(".pdf-container")[pageNumber - 1];
    div && div.scrollIntoView({ behavior: "smooth" });
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => setNavigating(false), 3000);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (!elementToAdd) return;
    dispatch(
      setMovingElement({
        ...ALLELEMENTS[elementToAdd.type],
        preview: true,
        show: false,
        ...elementToAdd.data,
      })
    );
  }, [elementToAdd]);

  const handleClose = () => {
    dispatch(setModalSignType(null));
  };

  const pdfsTotalPages = useMemo(() => {
    return Object.values(totalPages).reduce((partialSum, a) => partialSum + a, 0);
  }, [totalPages]);

  const onNextRequiredClick = () => {
    const elements = Array.from(document.getElementsByClassName("all_rendered_elements"));
    for(const element of elements) {
      const id = element.id.split("_")[1];
      const el = doc.elements.find(el => el._id == id);
      if(el.isRequired && (el.responses.length < 1 || !el.responses[0].value)){
        const elementRect = element.getBoundingClientRect();
        const containerRect = documentRef.current.getBoundingClientRect();
        const scrollTop = elementRect.top - containerRect.top + documentRef.current.scrollTop - 70;

        documentRef?.current?.scroll({
          top: scrollTop,
          behavior: 'smooth' // Optional: smooth scrolling animation
        });
        break;
      }
    }
  }

  return (
    <>
      <div className={`container !mb-10 ${classes}`}>
        {headerComponent}

        {modalSignType && (
          <SignatureModal
            handleApply={handleApply}
            handleClose={handleClose}
            element={
              modalSignType === "initials"
                ? lastInitialElement
                : lastSignatureElement
            }
          />
        )}

        <div
          ref={documentRef}
          onScroll={handleScroll}
          className={`border bg-gray-200 border-2 rounded-[20px] relative ${!isSend ? "border-gray-300 mt-6 h-[80vh]" : "h-full"} overflow-auto scrollbar-hide`}
        >
          <div>
            {movingElement &&
              movingElement.show &&
              getElement(-1, movingElement, doc.type)}
            {doc?.files?.map((pdf, itemindex) => (
              <Document
                key={itemindex}
                file={`${API_URL}pdf/${pdf}`}
                onLoadSuccess={(data) => {
                  setTotalPages((prevState) => ({
                    ...prevState,
                    [itemindex]: data.numPages
                  }));
                }}
              >
                {pdfsTotalPages &&
                  [...Array(totalPages[itemindex])].map(
                    (_, index) => {
                      const pageIndex = itemindex + "-" + index;
                      return (
                        <div
                          key={pageIndex}
                          ref={(ref) => (pagesRef ? pagesRef.current[pageIndex] = ref : null)}
                          className={`my-5 mx-auto pdf-container flex justify-center w-min h-full mb-4 radius-[12px]`}
                        >
                          <Page
                            pageNumber={index + 1}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            customTextRenderer={() => {}}
                            className={`drag-container-${pageIndex} border-2 border-gray-300`}
                            onClick={(e) => !movingElement.prevent && addElementToPage(e, pageIndex)}
                            onMouseLeave={handleMouseLeave}
                            onMouseMove={handleMouseMove}
                            scale={zoomLevel}
                          >
                            {doc.elements.map((item, index2) => {
                              if (item.page !== pageIndex) return null;
                              return getElement(index2, item, doc.type);
                            })}
                          </Page>
                        </div>
                      );
                    }
                  )}
              </Document>
            ))}
          </div>
          <div
            className={`w-fit sticky ${
              signComponent ? "bottom-[100px]" : "bottom-0"
            } left-[50%] translate-x-[-50%] z-50`}
          >
            <div className="p-2 px-6 rounded-t-lg flex justify-center items-center bg-[#1E1E1E]">
              {builderStatus=="fill" && !isDocumentSigned &&(
                <button
                  onClick={onNextRequiredClick}
                  className="text-red-500 font-bold py-2 mr-4"
                >
                  <div className="flex gap-1.5">
                    <span className="font-normal text-sm">Next Required</span>
                    <GoStop className="w-5 h-5" />
                  </div>
                </button>
              )}

              <button
                onClick={handleZoomIn}
                className="text-gray-500 font-bold py-2   "
              >
                <CiZoomIn className="w-6 h-6" />
              </button>
              <button
                onClick={handleZoomOut}
                className="text-gray-500 font-bold py-2 mr-4  "
              >
                <CiZoomOut className="w-6 h-6" />
              </button>
              <button
                onClick={() => prevPage(currentPage)}
                className="text-gray-500 font-bold py-2 "
              >
                <AiOutlineArrowUp className="w-6 h-6" />
              </button>
              <button
                onClick={() => nextPage(currentPage)}
                className="text-gray-500 font-bold py-2 "
              >
                <AiOutlineArrowDown className="w-6 h-6" />
              </button>
              <p className="text-gray-500 border-b border-gray-500 ml-4">
                {currentPage} - {pdfsTotalPages}
              </p>
            </div>
          </div>
          {signComponent}
        </div>
      </div>
    </>
  );
}
