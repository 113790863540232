import ElementsContainer from "../ElementsContainer";
import { dateFormat } from "../../../utils/functions"
import style from "../style.module.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Buttons/Button"
import { setActiveIndex, setModalSignType, updateRecipients } from "../../../redux/sign.slice";
import { useParams } from "react-router-dom";
import useRecipientFromElement from "../../../hooks/useRecipientFromElement"
import { cloneDeep } from "lodash";
import ScalableText from "../../ScalableText/ScalableText";

export default function SignatureBlock({ index, element }) {
  const dispatch = useDispatch();
  const { recipientId } = useParams(); 
  const recipient = useRecipientFromElement(element.recipient);
  const { doc, builderStatus, activeIndex, zoomLevel } = useSelector((state) => state.sign);
  const isActive = activeIndex === index && builderStatus === "edit";

  const updateRecipientEmail = (e) => {
    const value = e.target.value;
    var recipients = cloneDeep(doc.recipients);
    const recipientIndex = recipients.findIndex(recipient => recipient._id === recipientId);
    if(recipientIndex > -1) {
      recipients[recipientIndex].email = value;
      dispatch(updateRecipients(recipients));
    }
  }
  
  return (
    <ElementsContainer 
      index={index}
      element={element}
      lockAspectRatio={true}
      initialResizeHandleClasses={{ 
        right: isActive ? style.resizeDotRightCenter : null, 
        bottomRight: "pointer-events-none" 
      }}
    >
      <div className="h-full flex flex-col justify-center">
        <div className="flex items-center border-b-1 border-gray-400 mb-1 pb-1 gap-3" style={{ height: (element.style.height * zoomLevel) / 2 }}>
          <div className="w-[25%] h-full">
            <ScalableText text="Signature: " />
          </div>
          <div className="w-[75%] h-full flex">
            { element?.responses.length > 0 ? (
              <div onClick={() => {
                if(recipientId === element.recipient) {
                  dispatch(setActiveIndex(index))
                  dispatch(setModalSignType("signature"));
                }
              }} className="cursor-pointer w-full h-full">
                {element?.responses[0]?.type === "type" && (
                  <div className="max-w-full h-full mx-auto my-0 select-none flex flex-col" style={{height: (element.style.height * zoomLevel) / 2}}>
                    <ScalableText 
                      minFontSize={4} 
                      classes="font-[Allura] font-bold" 
                      text={element.responses[0]?.value}
                    />
                    <ScalableText 
                      minFontSize={4} 
                      maxFontSize={10} 
                      classes="whitespace-nowrap text-green-400" 
                      text={`(${dateFormat(element.responses[0]?.signedAt)})`}
                    />
                  </div>
                )}
                { element?.responses[0]?.type !== "type"  && (
                  <div className="h-full flex flex-col" style={{height: (element.style.height * zoomLevel) / 2}}>
                    <div style={{height: ((element.style.height * zoomLevel) / 2) * 0.6}}>
                      <img 
                        alt="Signature" 
                        src={element.responses[0]?.value}
                        className={`pointer-events-none max-w-[100%] max-h-full`}
                      /> 
                    </div>
                    <div 
                      style={{height: ((element.style.height * zoomLevel) / 2) * 0.4}}
                      className="border-t border-t-1 border-green-400 flex flex-nowrap justify-between overflow-hidden"
                    >
                      <ScalableText 
                        minFontSize={4} 
                        maxFontSize={10} 
                        classes="whitespace-nowrap text-green-400" 
                        text={`${element.responses[0]?.fullName} (${dateFormat(element.responses[0]?.signedAt)})`} 
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              recipientId === element.recipient && (
                <Button 
                  title={
                    <ScalableText 
                      minFontSize={4} 
                      maxFontSize={14} 
                      text="Click here to sign"
                      classes="whitespace-nowrap text-green-400" 
                    />
                  }
                  onClick={() => {
                    dispatch(setActiveIndex(index))
                    dispatch(setModalSignType("signature"))
                  }}
                  style={{ fontSize: 14 * zoomLevel }}
                  classes="bg-transparent border-0 !text-[#3CA970] hover:bg-transparent !p-0 text-sm"
                />
              )
            )}
          </div>
        </div>
        <div className="flex items-center border-b-1 border-gray-400 pb-1 gap-3" style={{ height: (element.style.height * zoomLevel) / 2 }}>
          <div className="w-[25%] h-full">
            <ScalableText text="Email:" />
          </div>
          {doc.type==="webform" && recipient.systemRecipient ? (
            <input 
              type="text"
              placeholder="Enter your email address"
              className="bg-transparent w-[75%]"
              style={{fontSize: 14*zoomLevel}}
              onChange={updateRecipientEmail}
            />
          ) : (
            <div className="w-[75%] h-full">
              <ScalableText text={recipient?.email} classes="font-light overflow-hidden text-ellipsis" />
            </div>
          )}
        </div>
      </div>
    </ElementsContainer>
  );
}
