import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../redux/auth.slice";
import { setDocumentSigned, setLoader } from "../../redux/app.slice";
import { useParams } from "react-router-dom";
import api from "../../utils/api";
import DocumentBuilder from "../../components/Builder/DocumentBuilder";
import Button from "../../components/Buttons/Button";
import moment from "moment";
import { BiMessageAlt } from "react-icons/bi"
import { RiCheckboxCircleFill, RiInformationFill } from "react-icons/ri"
import Tooltip from "../../components/General/Tooltip/Tooltip";
import DocumentESign from "../../components/Dropdowns/DocumentESign";
import DeclineNNote from "../../components/Modals/DeclineNNote";
import OptionsBtn from "../../components/Buttons/OptionsBtn";
import { 
  setDocument, 
  setActiveIndex, 
  setBuilderStatus, 
  setModalSignType, 
  updateElements, 
  updateRecipients
} from "../../redux/sign.slice";
import useSetElementResponse from "../../hooks/useSetElementResponse";
import toast from "react-hot-toast";
import RequireEmailModal from "../../components/Modals/RequireEmailModal";
import { cloneDeep } from "lodash";

export default function RecipientSign() {
  const dispatch = useDispatch();
  const { documentId, recipientId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { doc, activeIndex } = useSelector((state) => state.sign);
  const [docStatus, setDocStatus] = useState("sign");
  const [signedDocumentId, setSignedDocumentId] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const { setResponse } = useSetElementResponse(activeIndex, doc?.elements[activeIndex]);
  const isDocumentSignedState = useSelector((state) => state.app.isDocumentSignedState);

  useEffect(() => {
    getDocument();
  }, []);

  useEffect(() => {
    if(!user || !doc?.recipients) return;
    const recipient = doc?.recipients.some(r => r._id === recipientId && r.email === user.email);
    if(!recipient) setDocStatus("logoutToSign");
  }, [doc?.recipients, recipientId, user]);

  const getDocument = async () => {
    try {
      dispatch(setLoader(true));
      const { data } = await api.get(`/document/esign/${documentId}/${recipientId}`);
      dispatch(setDocument(data));
    } catch(error) {
      const message = error.response.data.message;
      if(message === "completed" || message === "canceled") setDocStatus(message);
      else setDocStatus("invalid");
    }
    dispatch(setLoader(null));
    dispatch(setBuilderStatus("fill"));
  }

  const signDocument = async (recipients) => {
    try {
      if(doc.type==="webform"){
        const signatureBlock = doc.elements.some(elm => elm.type==="signatureBlock" && elm.recipient===recipientId);
        if(!signatureBlock && !recipients) {
          setModalVisible(true);
          return;
        }
      }

      dispatch(setLoader(true));
      const responses = {};
      for(const element of doc.elements) {
        if(element.recipient === recipientId) {
          responses[element._id] = element.responses[0]
        }
      }
      let recipient;
      if(recipients) recipient = recipients.find(rec => rec._id === recipientId);
      else recipient = doc.recipients.find(rec => rec._id === recipientId);

      const { data } = await api.post(`/document/esign/${documentId}/${recipientId}`, { responses, recipient });
      setDocStatus("signed");
      setSignedDocumentId(data.documentId);
    } catch(error) {
      toast.error("Encountered an issue while attempting to sign the document. Please try again.");
    }
    dispatch(setLoader(null));
  }

  const handleRequireEmailSubmit = (values) => {
    var recipients = cloneDeep(doc.recipients);
    const recipientIndex = recipients.findIndex(recipient => recipient._id === recipientId);
    if(recipientIndex > -1) {
      recipients[recipientIndex].email = values.email;
      dispatch(updateRecipients(recipients));
      signDocument(recipients);
    }
  }

  const handleApply = (values) => {
    setResponse({
      type: values.input,
      value: values[values.input],
      fullName: values.input==="type"?values[values.input]:values.fullName,
      signedAt: moment().format()
    });
    dispatch(setActiveIndex(null));
    dispatch(setModalSignType(null));
  };

  const isDocumentSigned = useMemo(() => {
    let signed = true;
    if(!doc || !doc.elements || doc.elements.length < 1) return false;

    if(doc.type==="webform") {
      const signatureBlock = doc.elements.some(elm => elm.type==="signatureBlock" && elm.recipient===recipientId);
      const recipient = doc.recipients.find((rec) => rec._id === recipientId);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!recipient.email || (!emailRegex.test(recipient.email) && signatureBlock)) return false;
    }
    for(const element of doc.elements){
      if((element.isRequired && (element.responses.length < 1 || !element.responses[0].value)) && element.recipient === recipientId) {
        signed = false;
        break;
      }
    }
    dispatch(setDocumentSigned(signed));
    return signed;
  }, [doc?.elements, doc?.recipients, recipientId]);

  const declineSignCallback = async (values) => {
    try {
      dispatch(setLoader(true));
      await api.post(`/document/${documentId}/${recipientId}/cancel`, { message: values.message });
      setDocStatus("canceled");
    } catch (error){
      toast.error("Unable to process your request to decline the document.");
    }
    dispatch(setLoader(false));
  }

  const downloadDocument = async () => {
    try {
      if(!signedDocumentId) return;
      const response = await api.get(`/pdf/${signedDocumentId}/download?recipientId=${recipientId}`, {responseType: 'blob'});
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = doc.agreementName + '.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error){
      toast.error("Unable to download the PDF file for the document.");
    }
  }

  const downloadPdf = async () => {
    try {
      const response = await api.get(`/pdf/${documentId}/download/files`, {responseType: 'blob'});
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = doc.agreementName + '.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error){
      toast.error("Unable to download the document.");
    }
  }

  const clearAllResponses = () => {
    const newElements = doc?.elements.map(item => {
      if(item.recipient === recipientId) {
        return {
          ...item,
          responses: []
        }
      } 
      return item;
    });
    dispatch(updateElements(newElements));
  }

  const options = [
    { title: "Read document", onClick: downloadPdf },
    { title: "Decline to sign", onClick: () => setModalVisibility(true) },
    { title: "Clear Document Data", onClick: clearAllResponses },
    { title: "Download PDF", onClick: downloadPdf }
  ];

  return (
    <>
      { docStatus !== "sign" ? (
        <>
          {docStatus === "logoutToSign" && (
            <div className="min-h-[300px] flex flex-col justify-center w-1/3 mx-auto">
              <div className="flex items-center pb-5">
                <RiInformationFill size={50} color="#2196F3" />
                <h4 className="font-semibold text-2xl opacity-80 ml-3">Unauthorized Document Access</h4>
              </div>
              <p className="pb-4 opacity-70">You are signed in as {user?.email}, but this document was not intended for your signature.</p>
              <p className="pb-4 opacity-70">In order to view your document, please logout of this account.</p>
              <Button title="Logout" classes="w-fit" onClick={dispatch(userLogout())} />
            </div>
          )}
          {docStatus === "completed" && (
            <div className="min-h-[300px] flex flex-col justify-center w-1/3 mx-auto">
              <div className="flex items-center pb-5">
                <RiCheckboxCircleFill size={50} color="#3CA970" />
                <h4 className="font-semibold text-2xl opacity-80 ml-3">Document Already Signed</h4>
              </div>
              <p className="pb-4 opacity-70">This document has already been signed.</p>
            </div>
          )}
          {docStatus === "canceled" && (
            <div className="min-h-[300px] flex flex-col justify-center w-1/3 mx-auto">
              <div className="flex items-center pb-5">
                <RiInformationFill size={50} color="#2196F3" />
                <h4 className="font-semibold text-2xl opacity-80 ml-3">Document Canceled</h4>
              </div>
              <p className="pb-1 opacity-70">The document has been canceled.</p>
            </div>
          )}
          {docStatus === "signed" && (
            <div className="min-h-[300px] flex flex-col justify-center w-1/3 mx-auto">
              <div className="flex items-center pb-5">
                <RiCheckboxCircleFill size={50} color="#3CA970" />
                <h4 className="font-semibold text-2xl opacity-80 ml-3">You're all set</h4>
              </div>
              <p className="pb-4 opacity-70">You finished signing "{doc?.agreementName}".</p>
              <p className="pb-1 opacity-70">
                We will email the final document to all parties. 
                You can also <span className="font-semibold cursor-pointer text-[#3CA970]" onClick={downloadDocument}>download a copy </span> 
                of what you just signed.
              </p>
            </div>
          )}
          {docStatus === "invalid" && (
            <div className="min-h-[300px] flex flex-col justify-center w-1/3 mx-auto">
              <div className="flex items-center pb-5">
                <RiInformationFill size={50} color="#DC2626" />
                <h4 className="font-semibold text-2xl opacity-80 ml-3">Invalid Request</h4>
              </div>
              <p className="pb-4 opacity-70">This document may not be accessible due to an invalid link or permission settings.</p>
              <p className="pb-1 opacity-70">To resolve this issue and access the document, please check the document link for accuracy.</p>
            </div>
          )}
        </>
      ) : (
        <>
          <DeclineNNote 
            isOpen={modalVisibility} 
            label="Please enter the reason for declining below:"
            onClose={() => setModalVisibility(false)}
            onSubmit={declineSignCallback}
          />
          <DocumentBuilder
            handleApply={handleApply}
            headerComponent={
              <div className="border-2 rounded-[20px] mt-24 flex justify-between items-center py-4 px-8">
                <Tooltip
                  content={
                    <>
                      <h4 className="font-normal text-lg">{"Message from " + doc?.creator?.firstName + " " + doc?.creator?.lastName}</h4>
                      <p className="font-light">{doc?.message}</p>
                    </>
                  }
                >
                  <Button 
                    classes="!px-2 bg-transparent border-0 hover:bg-transparent"
                    icon={<BiMessageAlt size={26} color="#C4C4C4" />}
                  />
                </Tooltip>
                <h1 className="text-[25px] font-medium">{doc?.agreementName}</h1>
                <DocumentESign title="Options">
                  {options.map((item, index) => <OptionsBtn key={index} title={item.title} onClick={item.onClick} />)}
                </DocumentESign>
              </div>
            }
            signComponent={isDocumentSigned && (
              <div className="sticky bottom-0 w-full h-[100px] z-[99999]" style={{ background: 'rgba(30,30,30, 0.8)'}}>
                <div className="flex h-full items-center justify-center gap-6">
                  <p className="text-white text-sm">
                    By signing, I agree to this document, the Consumer Disclosure and <br/>to do business electronically with {doc?.creator?.firstName} {doc?.creator?.lastName}.
                  </p>
                  <Button
                    title="Click to sign"
                    classes="border-0"
                    onClick={() => signDocument(null)}
                  />
                </div>
              </div>
            )}
          />

          <RequireEmailModal 
            isOpen={isModalVisible}
            onClose={() => setModalVisible(false)}
            onSubmit={handleRequireEmailSubmit}
          />
        </>
      )}
    </>
  );
}